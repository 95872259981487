<template>
  <div id="portfolio-calculator">
    <div v-if="show_popup" id="portfolio-calculator-fullscreen">
      <div class="pop-up">
        <span @click="_closePopUp" class="material-icons-outlined" style="margin-left: 93%; margin-top: 10px; cursor: pointer;">close</span>
        <p style="text-align:left; margin: 0 0 20px 10px;">En Eurekers tenemos herramientas como esta, que te ayudarán a gestionar tus inversiones. Si quieres, déjanos tu teléfono e email para que te podamos enviar más información.</p>
        <InputTextOutline :input_label="input_portfolio[0].componentsConfig[0].input_label" :has_focus="input_portfolio[0].componentsConfig[0].has_focus" :icon_class="input_portfolio[0].componentsConfig[0].icon_class" :input_key_to_save_user_values="input_portfolio[0].componentsConfig[0].input_key_to_save_user_values" :input_class_character_counter="input_portfolio[0].componentsConfig[0].input_class_character_counter" :permission="input_portfolio[0].componentsConfig[0].permission" :icon_permission="input_portfolio[0].componentsConfig[0].icon_permission" :character_counter_permission="input_portfolio[0].componentsConfig[0].character_counter_permission" :helper_permission="input_portfolio[0].componentsConfig[0].helper_permission" :test_field_is_numeric="input_portfolio[0].componentsConfig[0].test_field_is_numeric" :length="input_portfolio[0].componentsConfig[0].length" :is_input_textarea="input_portfolio[0].componentsConfig[0].is_input_textarea" @_emit_method_order_input="_getValueFromEmailInput" style="margin: 0 0 10px 12.5%;"/>
        <InputTextOutline :input_label="input_portfolio[0].componentsConfig[1].input_label" :has_focus="input_portfolio[0].componentsConfig[1].has_focus" :icon_class="input_portfolio[0].componentsConfig[1].icon_class" :input_key_to_save_user_values="input_portfolio[0].componentsConfig[1].input_key_to_save_user_values" :input_class_character_counter="input_portfolio[0].componentsConfig[1].input_class_character_counter" :permission="input_portfolio[0].componentsConfig[1].permission" :icon_permission="input_portfolio[0].componentsConfig[1].icon_permission" :character_counter_permission="input_portfolio[0].componentsConfig[1].character_counter_permission" :helper_permission="input_portfolio[0].componentsConfig[1].helper_permission" :test_field_is_numeric="input_portfolio[0].componentsConfig[1].test_field_is_numeric" :length="input_portfolio[0].componentsConfig[1].length" :is_input_textarea="input_portfolio[0].componentsConfig[1].is_input_textarea" @_emit_method_order_input="_getValueFromPhoneInput" />
        <div>
          <Button @_emit_method_button="_validarInfo()" :button_name="button_name_list" :button_key="button_key_list" :button_icon="button_icon_list" :button_icon_permission="false" :button_icon_id="button_icon_id_list" />
        </div>
      </div>
    </div>
    <div id="simulator-full-screen">
      <div class="simulator-table">
        <div class="mdc-data-table mdc-data-table">
          <div class="mdc-data-table__table-container">
            <table class="mdc-data-table__table" aria-label="Dessert calories">
              <thead>
                <tr class="mdc-data-table__header-row">
                  <th v-for="(item, index) in table_header" :key="index" class="mdc-data-table__header-cell" role="columnheader" data-column-id="date" scope="col">{{item}}</th>
                </tr>
              </thead>
              <tbody class="mdc-data-table__content">
                <tr class="mdc-data-table__row">
                  <th class="mdc-data-table__cell" :style="{color: _colorProfitIndex(profit)}">{{profit}}</th>
                  <th class="mdc-data-table__cell">{{max_drawdown}}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="simulador-highchart">
        <div id="highchart-content"></div>
      </div>
      <div>
        <div class="simulador-input-autocomplete">
          <AutocompleteInput id="input"
            :options="options"
            :show="show"
            :disabled="disabled_ticker_to_check"
            @select="_selectSuggestions"
            @suggestions="_autocompleteSuggestions">
            <template slot="item" scope="option">
              <article class="media">
                <p>
                  <strong>{{ option.title }}</strong>
                  <br> {{ option.description }}
                </p>
              </article>
            </template>
          </AutocompleteInput>
          <p class="label-autocomplete-input">Comprobar otro ticker</p>
        </div>
      </div>
      <div class="simulator-chips-container">
        <div class="simulator-chips-box" v-for="(ticker, index) in user_tickers" :key="ticker">
          <span class="">{{ticker}}</span>
          <span @click="_deleteTicker(index)" class="material-icons icon-delete">cancel</span>
        </div>
      </div>

    </div>
  </div>
</template>



<script>
import { mapMutations } from "vuex";
import Vue from 'vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import Highcharts from "highcharts";
import AutocompleteInput from "@/components/helpers/AutocompleteInput";
import { MDCDataTable } from '@material/data-table';
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import InputPortfolio from './configs/portfolio_calculator.json';
import Button from '@/components/helpers/Button.vue';

export default {
  mixins:[APICaller],
  components: {
    AutocompleteInput,
    InputTextOutline,
    Button
  },
  name: 'PortfolioCalculator',

  metaInfo() {
    return {
      title: 'Creador de índices Eurekers',
      meta:[
        { name: 'description', content: "Esta herramienta permite seleccionar un grupo de tickers y crear un índice que representa una hipotética cartera de inversión, ofrecida por Eurekers"}
      ]
    }
  },

  props: {
    stock:{type: Object},
  },
  data: () => ({
    input_portfolio: InputPortfolio,
    counter_popup: -1, //empieza en -1 por el getData del mounted
    email: "",
    phone_number: "",
    show_popup: false,
    show: true,
    disabled_ticker_to_check: false,
    options: [],
    user_tickers: [],
    series: [{
      name: 'Indice de cartera',
      data: [0, 0]
    }],
    categories: [],
    user_portfolios: [],

    table_header: ['Rentabilidad', 'Max.Drawdown'],
    profit: 0,
    max_drawdown: 0,

    button_name_list: 'Enviar',
    button_key_list: 'widget_list',
    button_icon_list: '',
    button_icon_id_list: 'widget_list',
  }),
  computed: {
    ...mapGetters({crypto_enabled:'_g_crypto_enable'}),
    ...mapGetters(['_g_SelectedTicker']),
  },
  created() {
  },
  mounted() {
    new MDCDataTable(document.querySelector('.mdc-data-table'));
    this._addDataToHighchart();
    //this._APICallForList();
    this._getData();
    this._itsIframe();
  },

  methods: {
    _validarInfo: function(){
      this.result = true;
      this.result = this.result * this._validarEmail(this.email) * this._validarNumerotelefono(this.phone_number);
      if(this.result){
        var miStorage = window.localStorage;
        miStorage.setItem("sendIt", "true");

        let data = new URLSearchParams();
        data.append('email', this.email);
        data.append('tlf', this.phone_number);

        this._postInfo(data);

      }
    },

    _postInfo(data){
      let success = response => {
        if(response.status == 200){
          this._closePopUp();
        } else{
          this.$vueOnToast.pop('error', 'Ha ocurrido un error. Por favor, inténtelo de nuevo.');
        }
      };

      let url = "/calculadora/datos"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('300', 'Error en la calculadora.');

      this._postAPICall(url, data, successHandler, failureHandler);

    },


    _validarNumerotelefono: function(valor){
      if(/^\d{9}$/.test(valor)){
        return true;
      }else{
        this.$vueOnToast.pop('error', 'Numero de telefono incorrecto.');
        return false;
      }
    },
    _validarEmail: function(valor) {
      if(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(valor)){
       return true;
      }else{
        this.$vueOnToast.pop('error', 'Email incorrecto.');
        return false;
      } 
    },
    _openPopUp: function(){
      var miStorage = window.localStorage;
      var send = miStorage.getItem("sendIt");
      if((this.counter_popup == 5 || this.counter_popup == 15) && send == null){
        this.show_popup = true;
      }
    },
    _closePopUp: function(){
      this.show_popup = false;
    },
    _getValueFromEmailInput: function(value){
      this.email = value[0];
    },
    _getValueFromPhoneInput: function(value){
      this.phone_number = value[0];
    },
    _itsIframe: function(){
      var URLactual = window.location;
      if(URLactual.search == "?=iframe"){
        let sidebar = document.getElementById("sidebar-nav-container");
        sidebar.style.display = "none";
        document.getElementById('sidebar-nav-container').style.display = 'none';
        document.getElementById('grid-layout').style.margin = '0px';
        document.getElementById('grid-layout').style.padding = '0px';
      }
    },
    //Method that selects the color of the text according to whether the profitability is positive or negative.
    _colorProfitIndex: function(profit){
      let color;
      if(profit < 0){
        color = 'red';
        return color;
      } else{
        color= 'green';
        return color;
      }
    },

    //Method that configures the data with which the graphics will be printed
    _addDataToHighchart: function(){
      let self = this;
      Vue.nextTick(function() {
        Highcharts.chart('highchart-content',{
          chart: {
            type: 'line',
            zoomType: 'x',
          },
          title: {
            text: '',
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            title: '',
            type: 'logarithmic',
          },
          tooltip: {
            shared: true,
            crosshairs: true
          },
          series: self.series,
          colors: ['#4572A7', '#a4a5a7'],
        });
      })
    },

    /**_APICallForList: function(){
      let success= response => {this.user_portfolios = response.data;};

      let failure  =()=>{};

      let url = '/api/v1/portfolio/list';

      this._getAPICall(url, success, failure);

    },**/
    //call to api to collect all the data about the user's tickers and indexes
    _getData: function(){
      //let params = new URLSearchParams();
      //params.append('tickers', this.user_tickers.toString());
      this.counter_popup = this.counter_popup + 1;
      this._openPopUp();
      let query = "?tickers=" + this.user_tickers.toString();

      let success= response => {
        this.series = response.data['series'];
        this.categories = response.data['categories'][0].data;
        this.max_drawdown = response.data['max_drawdown'];
        this.profit = response.data['profit'];
        this._addDataToHighchart();
      };

      let url = "/api/v1/portfolio/calculate-portfolio-index-from-tickers" + query;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('202', 'Error calculando índice.');

      this._getAPICall(url, successHandler, failureHandler);

    },
    //method to remove one of the selected tickers
    _deleteTicker : function(index){
      this.user_tickers.splice(index, 1)
      
      this._getData();
    },
    _selectSuggestions: function(option) {
      if(this.user_tickers.indexOf(option.title) == -1){
        this.user_tickers.push(option.title);
        this._getData();
      }else{
        this.$vueOnToast.pop('error', "Error", "No pueden haber elementos repetidos. Este ticker ya se encuentra entre los seleccionados.");
      }
    },
    _autocompleteSuggestions: function(option) {
      this.options = [];
      this.ticker = option;
      this.currency = 'EUR';
      this.entries_analysis = [];

      let success = response => {
        this.options = [];
        response.data.forEach(element => {
          this.options.push({ title: element.symbol, description: element.description });
        }) 
      };

      let url = '/stockchart/api/search?limit=30&query=' + option + "&type=&exchange=";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('203', 'Error realizando la búsqueda.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    ...mapMutations(['setSelectedTicker']),
  },
}
</script>


<style lang="scss" scoped>
  @use "@material/data-table/data-table";
  @include data-table.core-styles;

  .mdc-data-table__header-cell,
  .mdc-data-table__cell {
    color: var(--color-text-primary);
    background-color: var(--color-active-surface);
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;}
  
  .mdc-data-table {
    width: 100%;}
  
  .mdc-data-table__row,
  .mdc-data-table__cell {
    background-color: var(--color-surface);
    color: #B00020;}
  .simulator-table {
    margin: 19px 44px 24px;
  }
  .simulador-highchart {
    margin: 20px 10px;
    border: 0.5px solid var(--color-text-secondary);
  }
  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .text-info-ticker {
    text-align: center;
    background-color: var(--color-surface);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
    padding: 7px 25px;

    span {
      font-size: 18px;
    }
  }
  .text-bold {
    font-weight: bold;
  }

  .simulator-button-add {
    background-color: #00AEDD;
    border-radius: 50%;
    color: #FFFFFF;
    border: none;
    margin-left: 20px;
    padding: 3px 5px 1px;
    box-shadow: 0px 1px 3px gray;
    cursor: pointer;
  }

  .simulator-chips-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 22px 10px 0;
  }

  .simulator-chips-box {
    display: flex;
    margin: 0 6px 12px;
    font-size: 14px;
    line-height: 20px;
    border: 0.5px solid rgba(0, 0, 0, 0.62);
    color: var(--color-text-secondary);
    border-radius: 16px;
    padding: 5px 10px;
    cursor: default;
    background-color: var(--color-surface);
  }

  .mdc-button {
    margin-top: 20px;
  }

  .icon-delete {
    cursor: pointer;
    font-size: 20px;
    color: var(--color-text-secondary);
    margin-left: 10px;
  }

  .simulador-button-save-index {
    text-align: center;
  }

  hr {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 20px 37px 43px;;
  }

  .simulator-title-index {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0F0F1A;
    margin-left: 37px;
  }

  .simulator-chips-margin {
    margin: 5px 30px 30px;
  }

  .simulador-input-autocomplete {
    text-align: center;
  }

  .label-autocomplete-input {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--color-text-secondary);
  }
  .control {
    margin: 0;
  }

  #highchart-content {
    height: 330px;
    width: 100%;
  }
  .highcharts-root {
    width: 380px !important;
  }

  #portfolio-calculator{
    overflow-y: hidden;
  }
  #portfolio-calculator-fullscreen {
    position: fixed;
    top: 0px;
    background: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    z-index: 999999;
    left: 0px;
  }
  .pop-up{
    position: absolute;
    top: 30%;
    left: 35%;
    height: 35%;
    width: 33%;
    background-color: white;
    box-shadow: 6px 6px 6px 3px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  .close{
    position: absolute;
    top: 27%;
    left: 68.5%;
    height: 2%;
    width: 2%;
    text-align: center;
    cursor: pointer;
  }
  .input-container-size{
    width: 75%;
    margin: 0 0 0 12.5%;
  }
  .mdc-button {
    margin: 10px 0;
    width: 40%;
  }
</style>